import React from 'react'
import styled from 'styled-components'
import RequestForm from "../../components/RequestForm"
import BreakpointUp from "../../components/Media/BreakpointUp"

const CardForm = styled.div`
	margin-bottom:30px;
  display:flex;
  flex-wrap:wrap;
  background: #fff;
  border:1px solid #ddd;
  width:100%;	
`
const CardBody = styled.div`
  padding:30px;
  width:100%;	
${BreakpointUp.xl`				
  padding:50px;  
`}
& .form-action{
  & .btn{
      max-width:200px;
      width:100%;
    }
  }
  
`
const CardTitle = styled.h3`
  font-size:24px;
  line-height:32px;
  color:#020304;
  margin-bottom:30px;  
	
`

const ProductForm = ({ location }) => {
	return(
		<CardForm className='card-form'>
			<CardBody className='card-body'>
				<CardTitle className='card-title'>To Request A Quote, Fill Out Our Short Form.</CardTitle>
        <RequestForm location={location} />
			</CardBody>
		</CardForm> 		
	)
	
}

export default ProductForm